import {
  Component, OnInit, ViewChild, OnDestroy,
  ElementRef, AfterViewInit, ChangeDetectorRef, HostListener
} from '@angular/core';
import {ROUTES} from './vertical-menu-routes.config';
import {HROUTES} from '../horizontal-menu/navigation-routes.config';

import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {customAnimations} from '../animations/custom-animations';
import {DeviceDetectorService} from 'ngx-device-detector';
import {ConfigService} from '../services/config.service';
import {Subscription} from 'rxjs';
import {LayoutService} from '../services/layout.service';
import {AuthService} from '../auth/auth.service';
import {LocalStorageService} from '../storage/local-storage.service';
import {PULSERIGHT_ROUTES} from './vertical-pulseright-menu-routes.config';

const moment = require('moment')

@Component({
  selector: 'app-sidebar',
  templateUrl: './vertical-menu.component.html',
  animations: customAnimations
})
export class VerticalMenuComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('toggleIcon') toggleIcon: ElementRef;
  public menuItems: any[];
  level = 0;
  logoUrl = 'assets/img/logo.png';
  public config: any = {};
  protected innerWidth: any;
  layoutSub: Subscription;
  configSub: Subscription;
  perfectScrollbarEnable = true;
  collapseSidebar = false;
  resizeTimeout;
  pendingAppointments: any;
  userData: any;
  orgDetails: any;
  OrgGroup: any;
  modules: any;
  totalApts = 0;
  data: any;

  constructor(
    private router: Router,
    public translate: TranslateService,
    private layoutService: LayoutService,
    private configService: ConfigService,
    private cdr: ChangeDetectorRef,
    public authService: AuthService,
    private deviceService: DeviceDetectorService,
    private storage: LocalStorageService
  ) {
    this.config = this.configService.templateConf;
    this.innerWidth = window.innerWidth;
    this.isTouchDevice();
  }


  async ngOnInit() {
    this.OrgGroup = JSON.parse(localStorage.getItem('organizationGroup'));
    await this.storage.getData('pulse-right').then(res => {
      if (res != null || res) {
        this.userData = res;
      }
      ;
    });
    // Watch storage for changes
    await this.storage.watchStorage().subscribe(res => {
      if (res != null || res) {
        this.userData = res;
        this.setUpModules();
      }
    });
    this.authService.fetchAllAppointmentsByOrg(this.userData).then((response: any) => {
    });
  }


  setUpModules() {
    if (this.userData.auth.data.role === 'Super Admin' || this.userData.auth.data.userType === 'Doctor') {
      this.authService.fetch_organization_Id(this.userData, this.userData.SelectedOrg).then(async (res: any) => {
        this.orgDetails = res;
      });
      this.loadMenus();
    }
  }

  loadMenus() {
    this.menuItems = PULSERIGHT_ROUTES;
    if (this.userData) {
      const role = this.userData.auth.data.userType;
      const menuData = this.menuItems.filter((item) => {
        if (item.category.includes(role)) {
          if (item.submenu.length > 0) {
            const itemSubmenu = item.submenu.filter((submenu) => {
              return submenu.category.includes(role);
            });
            item.submenu = itemSubmenu;
          }
          return item;
        }
      });
      this.menuItems = menuData.filter((item) => {
        if (item.title === 'Appointments') {
          item.submenu.map((submen) => {
            if (submen.title === 'List Appointments') {
              submen.badge = String(this.userData.appointmentList.length);
            }
            return submen;
          });
        }
        return item;
      });
    }
  }


  updateOrgDetails() {
    this.userData.auth.data.organization.filter(org => {
      if (org._id === this.userData.SelectedOrg) {
        this.orgDetails = org;
      }
    });
  }

  ngAfterViewInit() {

    this.configSub = this.configService.templateConf$.subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
      }
      this.loadLayout();
      this.cdr.markForCheck();

    });

    this.layoutSub = this.layoutService.overlaySidebarToggle$.subscribe(
      collapse => {
        if (this.config.layout.menuPosition === 'Side') {
          this.collapseSidebar = collapse;
        }
      });

  }


  @HostListener('window:resize', ['$event'])
  onWindowResize(event) {
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
    }
    this.resizeTimeout = setTimeout((() => {
      this.innerWidth = event.target.innerWidth;
      this.loadLayout();
    }).bind(this), 500);
  }

  loadLayout() {

    if (this.config.layout.menuPosition === 'Top') { // Horizontal Menu
      if (this.innerWidth < 1200) { // Screen size < 1200
        this.menuItems = HROUTES;
      }
    } else if (this.config.layout.menuPosition === 'Side') { // Vertical Menu{
      // this.menuItems = ROUTES;
    }


    if (this.config.layout.sidebar.backgroundColor === 'white') {
      this.logoUrl = 'assets/img/logo-dark.png';
    } else {
      this.logoUrl = 'assets/img/logo.png';
    }

    if (this.config.layout.sidebar.collapsed) {
      this.collapseSidebar = true;
    } else {
      this.collapseSidebar = false;
    }
  }

  toggleSidebar() {
    const conf = this.config;
    conf.layout.sidebar.collapsed = !this.config.layout.sidebar.collapsed;
    this.configService.applyTemplateConfigChange({layout: conf.layout});

    setTimeout(() => {
      this.fireRefreshEventOnWindow();
    }, 300);
  }

  fireRefreshEventOnWindow = function () {
    const evt = document.createEvent('HTMLEvents');
    evt.initEvent('resize', true, false);
    window.dispatchEvent(evt);
  };

  CloseSidebar() {
    this.layoutService.toggleSidebarSmallScreen(false);
  }

  isTouchDevice() {

    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();

    if (isMobile || isTablet) {
      this.perfectScrollbarEnable = false;
    } else {
      this.perfectScrollbarEnable = true;
    }

  }


  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
    if (this.configSub) {
      this.configSub.unsubscribe();
    }

  }

}
