import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild, UrlTree} from '@angular/router';
import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';
import {Observable} from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivateChild {

  constructor(private authService: AuthService, private router: Router) {
  }

  async canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const data = childRoute.data['access'];
    const isAuth = await this.authService.isAuthenticated(data);
    if (!isAuth) {
      return true;
      await this.router.navigate(['/login-admin']);
    } else {
      return true;
    }
  }

  // async CanActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
  //
  // }
}
