import {
  Component,
  Output,
  EventEmitter,
  OnDestroy,
  OnInit,
  AfterViewInit,
  ChangeDetectorRef,
  Inject,
  Renderer2,
  ViewChild,
  ElementRef,
  ViewChildren,
  QueryList,
  HostListener
} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {LayoutService} from '../services/layout.service';
import {Subscription} from 'rxjs';
import {ConfigService} from '../services/config.service';
import {FormControl, FormGroup, UntypedFormControl, Validators} from '@angular/forms';
import {LISTITEMS} from '../data/template-search';
import {Router} from '@angular/router';
import {AuthService} from '../auth/auth.service';
import {LocalStorageService} from '../storage/local-storage.service';
import {PULSERIGHT_ROUTES} from '../vertical-menu/vertical-pulseright-menu-routes.config';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {error} from 'protractor';
import {ToastrService} from 'ngx-toastr';
import {VerticalMenuComponent} from '../vertical-menu/vertical-menu.component';

const moment = require('moment');
const momentTimezone = require('moment-timezone');


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, AfterViewInit, OnDestroy {
  currentLang = 'en';
  selectedLanguageText = 'English';
  selectedLanguageFlag = './assets/img/flags/us.png';
  toggleClass = 'ft-maximize';
  placement = 'bottom-right';
  logoUrl = 'assets/img/logo.png';
  menuPosition = 'Side';
  isSmallScreen = false;
  protected innerWidth: any;
  searchOpenClass = '';
  transparentBGClass = '';
  hideSidebar = true;
  public isCollapsed = true;
  layoutSub: Subscription;
  configSub: Subscription;
  appointmentList: any[] = [];
  selectedOrgGroup: any;


  @ViewChild('search') searchElement: ElementRef;
  @ViewChildren('searchResults') searchResults: QueryList<any>;

  @Output()
  toggleHideSidebar = new EventEmitter<Object>();

  @Output()
  seachTextEmpty = new EventEmitter<boolean>();

  listItems = [];
  control = new UntypedFormControl();

  userData: any = {
    auth: {
      data: {
        avatar: '',
        name: ''
      }
    }
  };

  public organizationList: any = [];

  public config: any = {};
  disabled = false;
  today = new Date();
  file: any;
  closeResult = '';
  user: any;

  // This function is used in open


  // Update of Password..
  updateForm = new FormGroup({
    password: new FormControl('', Validators.required),
    confrimPassword: new FormControl('', Validators.required)
  });
  OnformSubmitted = false;
  public defaultSelectedOrg: any;

  editForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    gender: new FormControl('', [Validators.required]),
    number: new FormControl('', [Validators.required, Validators.pattern('[0-9]{10}')]),
  })
  orgGrpList: any = [];
  OrgGroup: any;

  constructor(public translate: TranslateService,
              private layoutService: LayoutService,
              private router: Router,
              private modalService: NgbModal,
              private configService: ConfigService,
              private cdr: ChangeDetectorRef,
              private auth: AuthService,
              private toastr: ToastrService,
              private storage: LocalStorageService) {

    const browserLang: string = translate.getBrowserLang();
    translate.use(browserLang.match(/en|es|pt|de/) ? browserLang : 'en');
    this.config = this.configService.templateConf;
    this.innerWidth = window.innerWidth;

    this.layoutSub = layoutService.toggleSidebar$.subscribe(
      isShow => {
        this.hideSidebar = !isShow;
      });

  }

  async ngOnInit() {
    this.OrgGroup = JSON.parse(localStorage.getItem('organizationGroup'));
    await this.storage.getData('pulse-right').then(res => {
      if (res != null || res) {
        this.userData = res;
      }
      ;
    });
    // Watch storage for changes
    await this.storage.watchStorage().subscribe(res => {
      if (res != null || res) {
        this.userData = res;
        this.user = this.userData.auth.data;
        if (this.user?.role === 'Super Admin') {
          this.loadOrgGrps();
        } else if (this.user.userType === 'Doctor' || this.user.userType === 'Staff') {
          this.organizationList = this.user.organization;
        } else {
          this.defaultSelectedOrg = this.userData.SelectedOrg;
          this.organizationList = this.OrgGroup.data.organization;
        }
        // default selected Organization then it chnages by call of onchange() method.
      }
    });
    await this.auth.fetchAllAppointmentsByOrg(this.userData)
      .then((resp: any) => {
      })
      .catch(err => {
        console.log(err)
      });

    await this.auth.dashboard_stats(this.userData.SelectedOrg).then((response: any) => {
    }).catch(() => {
    });

    this.auth.todayapts = this.todayAppointments();
    this.auth.yerterDayapts = this.yesterdatApts();
    this.auth.tommorrowApts = this.TomorrowApts();
    this.auth.tillMonthApts = this.Month_to_DateApts();
    this.auth.dashboardStats = this.dashboardUpdates();

    this.refreshData();

  }


  async loadOrgGrps() {
    await this.auth.fetchAll_OrgGroups(this.userData).then((resp: any) => {
      this.orgGrpList = resp.OrganizationGroups.filter((data) => {
        return !data.deleted;
      });
      this.defaultSelectedOrg = this.userData.SelectedOrg;
      this.selectedOrgGroup = this.userData.SelectedOrgGrp;
      const temp = this.orgGrpList.filter((orgGrp) => {
        return orgGrp._id === this.userData.SelectedOrgGrp;
      });
      this.organizationList = temp[0]?.organization;
    });
  }


  get Valiatdate() {
    return this.updateForm.controls;
  }

  OnSubmit(content) {
    this.OnformSubmitted = true;
    if (this.updateForm.value.password !== this.updateForm.value.confrimPassword) {
      alert('Password mismatch!');
      return;
    } else if (this.updateForm.invalid) {
      return;
    }
    const updateObj = [];
    if (this.updateForm.controls.password.dirty) {
      updateObj.push({propName: 'password', value: this.updateForm.value.password})
    }
    this.auth.ChangePassword(updateObj, this.userData).then((res) => {
      this.toastr.success('Password changed successfully', 'Message!', {
        positionClass: 'toast-top-center'
      });
      this.modalService.dismissAll();
      this.clear();
      this.auth.logout();
      this.router.navigate(['/login-admin']);
    }).catch((err) => {
      if (err) {
        alert('ChangePassword is failed..');
      }
    })
  }


  clear() {
    this.updateForm.reset();
    this.OnformSubmitted = false;
  }

  get lf() {
    return this.updateForm.controls;
  }


  ngAfterViewInit() {
    this.configSub = this.configService.templateConf$.subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
      }
      this.loadLayout();
      this.cdr.markForCheck();

    })
  }

  async refreshData() {
    this.listItems = LISTITEMS;

    if (this.innerWidth < 1200) {
      this.isSmallScreen = true;
    } else {
      this.isSmallScreen = false;
    }

    if (this.userData.appointmentList.length > 0) {
      let count = 0;
      for (const appData of this.userData.appointmentList) {
        if (!appData.status) {
          count = count + 1;
        }
      }
      PULSERIGHT_ROUTES[2].submenu[0].badge = count.toString();
    }
  }

  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
    if (this.configSub) {
      this.configSub.unsubscribe();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = event.target.innerWidth;
    if (this.innerWidth < 1200) {
      this.isSmallScreen = true;
    } else {
      this.isSmallScreen = false;
    }
  }

  loadLayout() {

    if (this.config.layout.menuPosition && this.config.layout.menuPosition.toString().trim() !== '') {
      this.menuPosition = this.config.layout.menuPosition;
    }

    if (this.config.layout.variant === 'Light') {
      this.logoUrl = 'assets/img/logo-dark.png';
    } else {
      this.logoUrl = 'assets/img/logo.png';
    }

    if (this.config.layout.variant === 'Transparent') {
      this.transparentBGClass = this.config.layout.sidebar.backgroundColor;
    } else {
      this.transparentBGClass = '';
    }

  }

  onSearchKey(event: any) {
    if (this.searchResults && this.searchResults.length > 0) {
      this.searchResults.first.host.nativeElement.classList.add('first-active-item');
    }

    if (event.target.value === '') {
      this.seachTextEmpty.emit(true);
    } else {
      this.seachTextEmpty.emit(false);
    }
  }

  removeActiveClass() {
    if (this.searchResults && this.searchResults.length > 0) {
      this.searchResults.first.host.nativeElement.classList.remove('first-active-item');
    }
  }

  onEscEvent() {
    this.control.setValue('');
    this.searchOpenClass = '';
    this.seachTextEmpty.emit(true);
  }

  onEnter() {
    if (this.searchResults && this.searchResults.length > 0) {
      const url = this.searchResults.first.url;
      if (url && url !== '') {
        this.control.setValue('');
        this.searchOpenClass = '';
        this.router.navigate([url]);
        this.seachTextEmpty.emit(true);
      }
    }
  }

  redirectTo(value) {
    this.router.navigate([value]);
    this.seachTextEmpty.emit(true);
  }


  ChangeLanguage(language: string) {
    this.translate.use(language);

    if (language === 'en') {
      this.selectedLanguageText = 'English';
      this.selectedLanguageFlag = './assets/img/flags/us.png';
    } else if (language === 'es') {
      this.selectedLanguageText = 'Spanish';
      this.selectedLanguageFlag = './assets/img/flags/es.png';
    } else if (language === 'pt') {
      this.selectedLanguageText = 'Portuguese';
      this.selectedLanguageFlag = './assets/img/flags/pt.png';
    } else if (language === 'de') {
      this.selectedLanguageText = 'German';
      this.selectedLanguageFlag = './assets/img/flags/de.png';
    }
  }

  ToggleClass() {
    if (this.toggleClass === 'ft-maximize') {
      this.toggleClass = 'ft-minimize';
    } else {
      this.toggleClass = 'ft-maximize';
    }
  }

  toggleSearchOpenClass(display) {
    this.control.setValue('');
    if (display) {
      this.searchOpenClass = 'open';
      setTimeout(() => {
        this.searchElement.nativeElement.focus();
      }, 0);
    } else {
      this.searchOpenClass = '';
    }
    this.seachTextEmpty.emit(true);


  }


  toggleNotificationSidebar() {
    this.layoutService.toggleNotificationSidebar(true);
  }

  toggleSidebar() {
    this.layoutService.toggleSidebarSmallScreen(this.hideSidebar);
  }

  logout() {
    this.auth.logout();
    this.router.navigate(['/login-admin']);
    localStorage.removeItem('pulse-right');
    history.pushState(null, '', location.href);
    window.onpopstate = () => {
      history.go(1);
    }
  }

  OnChangeOrgGrp(event: any) {
    this.selectedOrgGroup = event._id;
    this.auth.SelectedOrgGrpId(event._id);
    this.organizationList = event.organization;
  }

  OnChange(event) {
    this.auth.getSelectedOrgId(event._id);
    this.auth.menuLoader.next('OrgDetails');
    // Fetch all Appointments
    this.auth.fetchAllAppointmentsByOrg(this.userData)
      .then((resp: any) => {
      })
      .catch(err => {
        console.log(err)
      });
    this.auth.dashboard_stats(this.userData.SelectedOrg).then((response: any) => {
    }).catch(() => {
    });

    this.auth.todayapts = this.todayAppointments();
    this.auth.yerterDayapts = this.yesterdatApts();
    this.auth.tommorrowApts = this.TomorrowApts();
    this.auth.tillMonthApts = this.Month_to_DateApts();
    this.auth.dashboardStats = this.dashboardUpdates();
    this.router.navigate(['/dashboard'])
  }


  open(content, size) {
    if (this.userData) {
      this.auth.fetchUserData(this.userData).then((resp: any) => {
        this.editForm.controls['name'].setValue(resp.name)
        this.editForm.controls['email'].setValue(resp.email)
        this.editForm.controls['number'].setValue(resp.number.split('+91')[1])
        this.editForm.controls['gender'].setValue(resp.gender);
        this.user = resp;
      })
    }
    this.modalService.open(content, {size: size, backdrop: 'static'}).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      },
    );
  }


  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  todayAppointments() {
    let todayApt = [];
    todayApt = this.appointmentList.filter((appointment) => {
      return moment(this.today).format('L') === momentTimezone(appointment.date).tz('UTC').format('L');
    })
    return todayApt.length;
  }

  dashboardUpdates() {
    return this.userData.dashboard;
  }

  yesterdatApts() {
    let yestApt = [];
    yestApt = this.appointmentList.filter((appointment) => {
      return moment(this.today).subtract(1, 'days').format('L') === momentTimezone(appointment.date).tz('UTC').format('L');
    })
    return yestApt.length;
  }

  TomorrowApts() {
    let tomorrowApts = [];
    tomorrowApts = this.appointmentList.filter((appointment) => {
      return moment(this.today).add(1, 'days').format('L') === momentTimezone(appointment.date).tz('UTC').format('L');
    })
    return tomorrowApts.length;
  }

  Month_to_DateApts() {
    const currentDate = moment();

// Calculate the start of the month
    const startOfMonth = moment(currentDate).startOf('month');

    const mtdStart = startOfMonth.format();
    const mtdEnd = currentDate.format();
    const body = {
      'organizationId': this.userData.SelectedOrg,
      'startDate': moment(mtdStart).format(),
      'endDate': moment(mtdEnd).format()
    }
    if (this.userData.auth.data.role !== 'User') {
      this.auth.fetchAllAppointmentsByDate(this.userData, body.startDate, body.endDate).then((resp: any) => {
        this.auth.tillMonthApts = resp.length;
      });
    }

  }

  upload(file: any) {
    this.file = file.target.files[0];
    this.auth.addProfilePic(this.userData, this.file, this.userData.auth.data).then((resp: any) => {
      this.user = resp;
      this.toastr.success('Profile Picture Updated successfully', 'Message!', {
        positionClass: 'toast-top-center'
      });
    })
  }


  EditProfileDetails(modal: any) {
    this.OnformSubmitted = true;
    if (this.editForm.invalid) {
      return;
    }
    const body = [];
    for (const key in this.editForm.controls) {
      if (key === 'number' && this.editForm.controls[key].dirty) {
        body.push({
          propName: key, value: '+91' + this.editForm.controls[key].value
        })
      } else if (this.editForm.controls[key].dirty) {
        body.push({
          propName: key, value: this.editForm.controls[key].value
        })
      }
    }
    if (body.length === 0) {
      this.toastr.error('Edit any of the Fields', 'Error!', {
        positionClass: 'toast-top-center'
      });
      return;
    }

    this.auth.editPatient(body, this.userData, this.userData.auth.data._id).then((resp: any) => {
      if (resp.status === 500) {
        this.toastr.error('Failed to update the Profile', 'Error!', {
          positionClass: 'toast-top-center'
        });
      } else {
        this.toastr.success('Profile Updated successfully', 'Message!', {
          positionClass: 'toast-top-center'
        });
        this.user = resp;
      }
    }).catch(err => {
    });
    modal.dismiss('Cross-Click');
  }


}
