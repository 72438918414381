import {Component, ViewContainerRef, OnInit, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {Router, NavigationEnd, ActivatedRoute} from '@angular/router';
import {filter} from 'rxjs/operators';
import {AuthService} from './shared/auth/auth.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {

  subscription: Subscription;
  userData: any;

  constructor(private router: Router, private authService: AuthService, private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {

    const body = {'url': window.location.origin};
    // const body = {'url': 'https://login.scriptit.com.au'};
    this.authService.fetch_urlbased_org(body).then((resp: any) => {
      localStorage.setItem('organizationGroup', JSON.stringify(resp));
      const root = document.documentElement;
      root.style.setProperty('--primary-color', resp.data.primaryColor);
      root.style.setProperty('--secondary-color', resp.data.secondaryColor);
    });

    this.subscription = this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe(() => window.scrollTo(0, 0));
    this.userData = JSON.parse(localStorage.getItem('pulse-right'));
    this.activatedRoute.queryParams.subscribe((resp: any) => {
      localStorage.setItem('organization', resp.orgId);
      // @ts-ignore
    });
    // https://pop.posdirect.com.au
    // http://localhost:4200
    this.authService.fetchPlatForm(body).then((resp: any) => {
      localStorage.setItem('platform', JSON.stringify(resp.data));
    });
  }


  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
