import {RouteInfo} from './vertical-menu.metadata';
import {pendingAppointments} from './vertical-menu-routes.config';

// Sidebar menu Routes and data
// ALWAYS FOLLOW THIS STRUCTURE SuperAdmin -> Admin -> Staff -> User
export let PULSERIGHT_ROUTES: RouteInfo[] = [
  {
    path: '',
    title: 'Doctors',
    icon: 'fa-solid fa-user-doctor',
    class: 'dropdown nav-item has-sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [
      {
        path: '/list-doctors',
        title: 'List Doctors',
        icon: 'ft-arrow-right submenu-icon',
        class: 'dropdown-item',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        category: ['Super Admin', 'Admin', 'Staff', 'Manager']
      },
      {
        path: '/create-doctors',
        title: 'Create Doctors',
        icon: 'ft-arrow-right submenu-icon',
        class: 'dropdown-item',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        category: ['Super Admin', 'Admin', 'Manager']
      },
    ],
    category: ['Super Admin', 'Admin', 'Staff', 'Manager',]
  },
  {
    path: '',
    title: 'Services',
    icon: 'ft-briefcase',
    class: 'dropdown nav-item has-sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [
      {
        path: '/list-services',
        title: 'List Services',
        icon: 'ft-arrow-right submenu-icon',
        class: 'dropdown-item',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        category: ['Super Admin', 'Admin', 'Staff', 'Manager']
      },
      {
        path: '/create-services',
        title: 'Create Services',
        icon: 'ft-arrow-right submenu-icon',
        class: 'dropdown-item',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        category: ['Super Admin', 'Admin', 'Manager']
      },
    ],
    category: ['Super Admin', 'Admin', 'Staff', 'Manager']
  },
  {
    path: '',
    title: 'Appointments',
    icon: 'ft-clipboard',
    class: 'dropdown nav-item has-sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [
      {
        path: '/list-appointments',
        title: 'List Appointments',
        icon: 'ft-arrow-right submenu-icon',
        class: 'dropdown-item',
        badge: '',
        badgeClass: 'badge badge-pill badge-danger mt-1 mr-2 no-right',
        isExternalLink: false,
        submenu: [],
        category: ['Super Admin', 'Admin', 'Staff', 'Manager']
      },
      {
        path: '/create-appointments',
        title: 'Create Appointments',
        icon: 'ft-arrow-right submenu-icon',
        class: 'dropdown-item',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        category: ['Super Admin', 'Admin', 'Staff', 'Manager']
      },
    ],
    category: ['Super Admin', 'Admin', 'Staff', 'Manager']
  },
  {
    path: '/doctor-appointments',
    title: 'My Appointments',
    icon: 'ft-clipboard',
    class: 'dropdown-item',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    category: ['Doctor']
  },
  {
    path: '',
    title: 'Patients',
    icon: 'ft-user-plus',
    class: 'dropdown nav-item has-sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [
      {
        path: '/list-patients',
        title: 'List Patients',
        icon: 'ft-arrow-right submenu-icon',
        class: 'dropdown-item',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        category: ['Super Admin', 'Admin', 'Staff']
      },
      {
        path: '/add-patient',
        title: 'Add Patients',
        icon: 'ft-arrow-right submenu-icon',
        class: 'dropdown-item',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        category: ['Super Admin', 'Admin', 'Staff', 'Manager']
      }, {
        path: '/create-order',
        title: 'Add Prescription',
        icon: 'ft-arrow-right submenu-icon',
        class: 'dropdown-item',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        category: ['Super Admin', 'Doctor']
      },

    ],
    category: ['Super Admin', 'Doctor']
  }, {
    path: '/organization-group',
    title: 'Organization Group',
    icon: 'fa-solid fa-home',
    class: 'dropdown nav-item',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    category: ['Super Admin', 'Admin']
  }
];

