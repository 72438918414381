<nav class="navbar navbar-expand-lg navbar-light header-navbar {{transparentBGClass}}"
     [ngClass]="{'navbar-brand-center': menuPosition === 'Top', 'navbar-static': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Static' , 'navbar-sticky': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Fixed', 'fixed-top': isSmallScreen && menuPosition === 'Top'}">
  <div class="container-fluid navbar-wrapper">
    <div class="navbar-header d-flex">
      <div class="navbar-toggle menu-toggle d-xl-none d-block float-left align-items-center justify-content-center"
           data-toggle="collapse" (click)="toggleSidebar()">
        <i class="ft-menu font-medium-3"></i>
      </div>
      <!--      <ul class="navbar-nav" (clickOutside)="toggleSearchOpenClass(false)">-->
      <!--        <li class="nav-item mr-2 d-none d-lg-block">-->
      <!--          <a class="nav-link apptogglefullscreen" id="navbar-fullscreen" href="fullscreen:;" appToggleFullscreen-->
      <!--            (click)="ToggleClass()"><i class=" {{toggleClass}} font-medium-3"></i></a>-->
      <!--        </li>-->
      <!--      </ul>-->
      <div class="navbar-brand-center">
        <div class="navbar-header">
          <ul class="navbar-nav">
            <li class="nav-item">
              <div class="logo"><a class="logo-text" [routerLink]="['/']" *ngIf="config?.layout.menuPosition === 'Top'">
                <div class="logo-img"><img class="logo-img" alt="Apex logo" [src]="logoUrl"></div>
                <span
                  class="text">APEX</span>
              </a></div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="navbar-container">
      <div class="collapse navbar-collapse d-block" id="navbarSupportedContent">
        <ul class="navbar-nav">

          <li *ngIf="this.userData.auth.data?.role === 'Super Admin'" class="i18n-dropdown dropdown nav-item mr-2"
              display="dynamic" ngbDropdown>
            <!--Organization Dropdown list-->
            <ng-select placeholder="Select Organization Group" [items]="orgGrpList" bindValue="_id" bindLabel="Name"
                       [(ngModel)]="this.userData.SelectedOrgGrp"
                       [disabled]="true"
                       (change)="OnChangeOrgGrp($event)" style="width: 250px">
            </ng-select>
          </li>
          <li class="i18n-dropdown dropdown nav-item mr-2" display="dynamic" ngbDropdown>
            <ng-select placeholder="Select Organization "
                       [(ngModel)]="this.userData.SelectedOrg"
                       [items]="organizationList" bindValue="_id" bindLabel="name"
                       (change)="OnChange($event)"  style="width: 250px">
            </ng-select>
          </li>
          <!--          <li class="dropdown nav-item" placement="bottom-left" display="static" ngbDropdown >-->
          <!--            <a class="nav-link dropdown-toggle dropdown-notification p-0 mt-2" id="drp-notification" href="javascript:;"-->
          <!--              data-toggle="dropdown" ngbDropdownToggle><i class="ft-bell font-medium-3"></i><span-->
          <!--                class="notification badge badge-pill badge-danger">4</span></a>-->
          <!--            <ul ngbDropdownMenu-->
          <!--              class="notification-dropdown dropdown-menu dropdown-menu-media dropdown-menu-right m-0 overflow-hidden">-->

          <!--              <li class="dropdown-menu-header">-->
          <!--                <div class="dropdown-header d-flex justify-content-between m-0 px-3 py-2 white bg-primary">-->
          <!--                  <div class="d-flex">-->
          <!--                    <i class="ft-bell font-medium-3 d-flex align-items-center mr-2"></i><span class="noti-title">7 New-->
          <!--                      Notification</span>-->
          <!--                  </div>-->
          <!--                  <span class="text-bold-400 cursor-pointer">Mark all as read</span>-->
          <!--                </div>-->
          <!--              </li>-->
          <!--              <li class="scrollable-container" [perfectScrollbar]>-->
          <!--                <a class="d-flex justify-content-between" href="javascript:void(0)">-->
          <!--                  <div class="media d-flex align-items-center">-->
          <!--                    <div class="media-left">-->
          <!--                      <div class="mr-3">-->
          <!--                        <img class="avatar" src="assets/img/portrait/small/avatar-s-20.png" alt="avatar" height="45"-->
          <!--                          width="45" />-->
          <!--                      </div>-->
          <!--                    </div>-->
          <!--                    <div class="media-body">-->
          <!--                      <h6 class="m-0">-->
          <!--                        <span>Kate Young</span><small class="grey lighten-1 font-italic float-right">5 mins-->
          <!--                          ago</small>-->
          <!--                      </h6>-->
          <!--                      <small class="noti-text">Commented on your photo</small>-->
          <!--                      <h6 class="noti-text font-small-3 m-0">-->
          <!--                        Great Shot John! Really enjoying the composition on this-->
          <!--                        piece.-->
          <!--                      </h6>-->
          <!--                    </div>-->
          <!--                  </div>-->
          <!--                </a>-->
          <!--                <a class="d-flex justify-content-between" href="javascript:void(0)">-->
          <!--                  <div class="media d-flex align-items-center">-->
          <!--                    <div class="media-left">-->
          <!--                      <div class="mr-3">-->
          <!--                        <img class="avatar" src="assets/img/portrait/small/avatar-s-11.png" alt="avatar" height="45"-->
          <!--                          width="45" />-->
          <!--                      </div>-->
          <!--                    </div>-->
          <!--                    <div class="media-body">-->
          <!--                      <h6 class="m-0">-->
          <!--                        <span>Andrew Watts</span><small class="grey lighten-1 font-italic float-right">49 mins-->
          <!--                          ago</small>-->
          <!--                      </h6>-->
          <!--                      <small class="noti-text">Liked your album: UI/UX Inspo</small>-->
          <!--                    </div>-->
          <!--                  </div>-->
          <!--                </a>-->
          <!--                <a class="d-flex justify-content-between read-notification" href="javascript:void(0)">-->
          <!--                  <div class="media d-flex align-items-center py-0 pr-0">-->
          <!--                    <div class="media-left">-->
          <!--                      <div class="mr-3">-->
          <!--                        <img src="assets/img/icons/sketch-mac-icon.png" alt="avatar" height="45" width="45" />-->
          <!--                      </div>-->
          <!--                    </div>-->
          <!--                    <div class="media-body">-->
          <!--                      <h6 class="m-0">Update</h6>-->
          <!--                      <small class="noti-text">MyBook v2.0.7</small>-->
          <!--                    </div>-->
          <!--                    <div class="media-right">-->
          <!--                      <div class="border-left">-->
          <!--                        <div class="px-4 py-2 border-bottom">-->
          <!--                          <h6 class="m-0 text-bold-600">Update</h6>-->
          <!--                        </div>-->
          <!--                        <div class="px-4 py-2 text-center">-->
          <!--                          <h6 class="m-0">Close</h6>-->
          <!--                        </div>-->
          <!--                      </div>-->
          <!--                    </div>-->
          <!--                  </div>-->
          <!--                </a>-->
          <!--                <a class="d-flex justify-content-between read-notification" href="javascript:void(0)">-->
          <!--                  <div class="media d-flex align-items-center">-->
          <!--                    <div class="media-left">-->
          <!--                      <div class="avatar bg-primary bg-lighten-3 mr-3 p-1">-->
          <!--                        <span class="avatar-content font-medium-2">LD</span>-->
          <!--                      </div>-->
          <!--                    </div>-->
          <!--                    <div class="media-body">-->
          <!--                      <h6 class="m-0">-->
          <!--                        <span>Registration done</span><small class="grey lighten-1 font-italic float-right">6 hrs-->
          <!--                          ago</small>-->
          <!--                      </h6>-->
          <!--                    </div>-->
          <!--                  </div>-->
          <!--                </a>-->
          <!--                <div class="cursor-pointer">-->
          <!--                  <div class="media d-flex align-items-center justify-content-between">-->
          <!--                    <div class="media-left">-->
          <!--                      <div class="media-body">-->
          <!--                        <h6 class="m-0">New Offers</h6>-->
          <!--                      </div>-->
          <!--                    </div>-->
          <!--                    <div class="media-right">-->
          <!--                      <div class="custom-switch custom-control">-->
          <!--                        <input type="checkbox" class="custom-control-input" id="custom-switch-1111" checked>-->
          <!--                        <label class="custom-control-label mr-1" for="custom-switch-1111">-->
          <!--                        </label>-->
          <!--                      </div>-->
          <!--                    </div>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--                <div class="d-flex justify-content-between cursor-pointer read-notification">-->
          <!--                  <div class="media d-flex align-items-center">-->
          <!--                    <div class="media-left">-->
          <!--                      <div class="avatar bg-danger bg-lighten-4 mr-3 p-1">-->
          <!--                        <span class="avatar-content font-medium-2"><i class="ft-heart text-danger"></i></span>-->
          <!--                      </div>-->
          <!--                    </div>-->
          <!--                    <div class="media-body">-->
          <!--                      <h6 class="m-0">-->
          <!--                        <span>Application approved</span><small class="grey lighten-1 font-italic float-right">18 hrs-->
          <!--                          ago</small>-->
          <!--                      </h6>-->
          <!--                    </div>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--                <a class="d-flex justify-content-between read-notification" href="javascript:void(0)">-->
          <!--                  <div class="media d-flex align-items-center">-->
          <!--                    <div class="media-left">-->
          <!--                      <div class="mr-3">-->
          <!--                        <img class="avatar" src="assets/img/portrait/small/avatar-s-6.png" alt="avatar" height="45"-->
          <!--                          width="45" />-->
          <!--                      </div>-->
          <!--                    </div>-->
          <!--                    <div class="media-body">-->
          <!--                      <h6 class="m-0">-->
          <!--                        <span>Anna Lee</span><small class="grey lighten-1 font-italic float-right">27 hrs ago</small>-->
          <!--                      </h6>-->
          <!--                      <small class="noti-text">Commented on your photo</small>-->
          <!--                      <h6 class="noti-text font-small-3 text-bold-500 m-0">-->
          <!--                        Woah!Loving these colors! Keep it up-->
          <!--                      </h6>-->
          <!--                    </div>-->
          <!--                  </div>-->
          <!--                </a>-->
          <!--                <div class="d-flex justify-content-between cursor-pointer read-notification">-->
          <!--                  <div class="media d-flex align-items-center">-->
          <!--                    <div class="media-left">-->
          <!--                      <div class="avatar bg-info bg-lighten-4 mr-3 p-1">-->
          <!--                        <div class="avatar-content font-medium-2">-->
          <!--                          <i class="ft-align-left text-info"></i>-->
          <!--                        </div>-->
          <!--                      </div>-->
          <!--                    </div>-->
          <!--                    <div class="media-body">-->
          <!--                      <h6 class="m-0">-->
          <!--                        <span>Report generated</span><small class="grey lighten-1 font-italic float-right">35 hrs-->
          <!--                          ago</small>-->
          <!--                      </h6>-->
          <!--                    </div>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--                <a class="d-flex justify-content-between read-notification" href="javascript:void(0)">-->
          <!--                  <div class="media d-flex align-items-center">-->
          <!--                    <div class="media-left">-->
          <!--                      <div class="mr-3">-->
          <!--                        <img class="avatar" src="assets/img/portrait/small/avatar-s-7.png" alt="avatar" height="45"-->
          <!--                          width="45" />-->
          <!--                      </div>-->
          <!--                    </div>-->
          <!--                    <div class="media-body">-->
          <!--                      <h6 class="m-0">-->
          <!--                        <span>Oliver Wright</span><small class="grey lighten-1 font-italic float-right">2 days-->
          <!--                          ago</small>-->
          <!--                      </h6>-->
          <!--                      <small class="noti-text">Liked your album: UI/UX Inspo</small>-->
          <!--                    </div>-->
          <!--                  </div>-->
          <!--                </a>-->
          <!--              </li>-->
          <!--              <li class="dropdown-menu-footer">-->
          <!--                <div class="noti-footer text-center cursor-pointer primary border-top text-bold-400 py-1">-->
          <!--                  Read All Notifications-->
          <!--                </div>-->
          <!--              </li>-->
          <!--            </ul>-->
          <!--          </li>-->
          <li class="dropdown nav-item mr-1" placement="bottom-left" display="static" ngbDropdown>
            <a class="nav-link dropdown-toggle user-dropdown d-flex align-items-end" id="dropdownBasic2"
               href="javascript:;" data-toggle="dropdown" ngbDropdownToggle>
              <div class="user d-md-flex d-none mr-2">
                <span class="text-right">{{this.user?.name}}</span><span
                class="text-right text-muted font-small-3">Available</span>
              </div>
              <img class="avatar" src="{{this.user?.avatar}}" alt="avatar" height="35" width="35"/>
            </a>
            <div class="dropdown-menu text-left dropdown-menu-right m-0 pb-0" aria-labelledby="dropdownBasic2"
                 ngbDropdownMenu>
              <a class="dropdown-item" (click)="open(myprofile,'xl')">
                <div class="d-flex align-items-center">
                  <i class="ft-user mr-2"></i><span>My Profile</span>
                </div>
              </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" (click)="open(content,'lg')">
                <div class="d-flex align-items-center">
                  <i class="ft-lock mr-2"></i><span>Change Password</span>
                </div>
              </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" (click)="this.logout();">
                <div class="d-flex align-items-center">
                  <i class="ft-power mr-2"></i><span>Logout</span>
                </div>
              </a>
            </div>
          </li>
          <!--          <li class="nav-item d-none d-lg-block mr-2 mt-1">-->
          <!--            <a class="nav-link notification-sidebar-toggle" (click)="toggleNotificationSidebar();"><i-->
          <!--                class="ft-align-right font-medium-3"></i></a>-->
          <!--          </li>-->
        </ul>
      </div>
    </div>
  </div>
</nav>


<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Change Password</h4>
    <button type="button" class="btn btn-default ft ft-x" aria-label="Close"
            (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <form [formGroup]="updateForm" (ngSubmit)="OnSubmit(content)">
      <div class="form-group row">
        <label class="col-md-5 col-form-label" for="horizontal-form-1">New Password</label>
        <div class="col-md-9">
          <input type="password" class="form-control square" id="horizontal-form-1" name="pwd"
                 formControlName="password">
          <p class="text-danger" *ngIf="OnformSubmitted && (Valiatdate.password.invalid)">New password is required!</p>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-5 col-form-label" for="horizontal-form-1">Confrim Password</label>
        <div class="col-md-9">
          <input type="password" class="form-control square" id="horizontal-form-2" name="cpwd"
                 formControlName="confrimPassword">
          <p class="text-danger" *ngIf="OnformSubmitted && (Valiatdate.confrimPassword.invalid)">Confirm password is
            required!</p>
        </div>
      </div>
      <button type="submit" class="btn btn-primary mr-2"><i class="ft-lock mr-1"></i>Change Password</button>
    </form>
  </div>
  <div class="modal-footer">
  </div>
</ng-template>


<ng-template #myprofile let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="myprofile">My Profile</h4>
    <button type="button" class="btn btn-default ft ft-x" aria-label="Close"
            (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12 col-md-12 p-1 text-center">
        <img class="avatar" src="{{this.user?.avatar}}" alt="avatar" height="80" width="80"/><br>
        <input type="file" id="avatar" style="display: none;" accept="image/png,image/jpeg,image/jpg"
               (change)="upload($event)">
        <label for="avatar" class="cursor-pointer text-info">Edit Profile Picture</label>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-12">
        <form [formGroup]="editForm" (ngSubmit)="EditProfileDetails(modal)">
          <div class="row">
            <div class="col-md-6 col-12">
              <div class="form-group row">
                <label class="col-md-3 col-form-label" for="horizontal-form-1">Name</label>
                <div class="col-md-9">
                  <input type="text" class="form-control square" formControlName="name" id="horizontal-form-1"
                         name="name">
                  <p *ngIf="OnformSubmitted && editForm.controls['name'].hasError('required')" class="text-danger">Name
                    is required</p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="form-group row">
                <label class="col-md-3 col-form-label" for="horizontal-form-2">Email</label>
                <div class="col-md-9">
                  <input type="text" class="form-control square" formControlName="email" id="horizontal-form-2"
                         name="email">
                  <p *ngIf="editForm.controls['email'].hasError('required') && OnformSubmitted" class="text-danger">
                    Email is
                    required</p>
                  <p *ngIf="editForm.controls['email'].hasError('email')" class="text-danger">Invalid email</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-md-3">
            <div class="col-md-6 col-12">
              <div class="form-group row">
                <label class="col-md-3 col-form-label">Phone number</label>
                <div class="col-md-9">
                  <fieldset>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1">+61</span>
                      </div>
                      <input type="text" formControlName="number" class="form-control" placeholder="Phone number">
                    </div>
                    <p *ngIf="editForm.controls['number'].hasError('pattern')" class="text-danger">Invalid Phone
                      Number</p>
                    <p *ngIf="editForm.controls['number'].hasError('required') && OnformSubmitted" class="text-danger">
                      Phone number is
                      required</p>
                  </fieldset>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="form-group row">
                <label class="col-md-3 col-form-label">Gender</label>
                <div class="col-md-9">
                  <ul class="list-unstyled mb-0">
                    <li class="d-inline-block mr-2">
                      <div class="radio">
                        <input type="radio" name="gender" value="male" id="radio1"
                               [checked]="this.userData.auth.data.gender == 'male'"
                               formControlName="gender">
                        <label for="radio1">Male</label>
                      </div>
                    </li>
                    <li class="d-inline-block mr-2">
                      <div class="radio">
                        <input type="radio" name="gender" value="female"
                               [checked]="this.userData.auth.data.gender == 'female'"
                               id="radio2" formControlName="gender">
                        <label for="radio2">Female</label>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <button type="submit" class="btn btn-primary float-right"><i class="ft-check-square mr-1"></i>Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>
