import {Router} from '@angular/router';
import {Injectable, OnInit} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {LocalStorageService} from '../storage/local-storage.service';
import {io} from 'socket.io-client';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {ToastrService} from 'ngx-toastr';

const moment = require('moment');


@Injectable()
export class AuthService implements OnInit {

  todayapts: number;
  yerterDayapts: number;
  tommorrowApts: number;
  tillMonthApts: any;
  orgLogo: any;
  dashboardStats: any;
  private booking_socket: any;
  private queue_socket: any;
  menuLoader = new BehaviorSubject<string>('OrgDetails');

  public userData: any = {
    auth: {},
    doctorList: [],
    SelectedOrg: '',
    SelectedOrgGrp: '',
    modules: [],
    patientList: [],
    StaffList: [],
    serviceList: [],
    appointmentList: [],
    timeOffList: [],
    dashboard: {},
    overTime: [],
    totalapts: 0,
  };

  showUserMng: string;
  public selectedOrgId: any = '';


  public countries = [
    '+91'
  ]

  public baseURL = 'https://backend.posdirect.pulseright.io:3000/';
  public cloneUrl = 'https://backend.scriptit.com.au:3000/';
  // public baseURL = 'http://192.168.43.177:8080/';
  // public cloneUrl = 'http://192.168.43.177:8010/';


  constructor(private http: HttpClient, public router: Router, private storage: LocalStorageService, public toastr: ToastrService) {
    // @ts-ignore

    this.booking_socket = io('https://backend.posdirect.pulseright.io:3000/booking');
    this.queue_socket = io('https://backend.posdirect.pulseright.io:3000/queue');
    //
    // this.booking_socket = io('http://192.168.43.177:8080/booking');
    // this.queue_socket = io('http://192.168.43.177:8080/queue');
  }

  ngOnInit(): void {
    this.storage.getData('pulse-right').then(res => {
      if (res != null || res) {
        this.userData = res;
        console.log(this.userData)
      }
      ;
    });
    this.storage.watchStorage().subscribe(res => {
      if (res != null || res) {
        this.userData = res;

      }
      ;
    });
    // console.log(thia)
  }

  // TODO MJ - Still needs work as we need to disable back navigation on this website
  async checkLocalData() {
    const localData = new Promise(async (resolve, reject) => {
      await this.storage.getData('pulse-right').then(res => {
        resolve(res);
      });
    });
    return localData;
  }


  deleteUserService(serviceId, user, doctorId) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.delete(this.baseURL + 'service/userService/delete/' + serviceId, {headers}).toPromise().then((res) => {
      return res;
    });
  }


// Integration of WebSocket


  registerPatient(newPatient, user) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post(this.baseURL + 'users/register', newPatient, {headers}).toPromise().then((res) => {
      return res;
    }).catch(err => {
      return err;
    });
  }

  Failed() {
    this.toastr.clear();
    this.toastr.error('Patient registration Failed!', 'Error', {
      positionClass: 'toast-top-center'
    })
  }

  ExistsUser() {
    this.toastr.clear();
    this.toastr.error('User Already Exists!', 'Message', {
      positionClass: 'toast-top-center'
    })
  }


  registerStaff(staff, user) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.post(this.baseURL + 'users/register', staff, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    });
  }

  fetchPlatForm(body: any) {
    return this.http.post(this.cloneUrl + 'platform/urlBasedOnPlatform', body).toPromise().then(async (res: any) => {
      if (res.data !== 'No platform found for the requested URL') {
        localStorage.setItem('platform', JSON.stringify(res.data));
      }
      return res;
    });
  }


  registerDoctor(user) {
    return this.http.post(this.baseURL + 'users/register', user).toPromise().then((res) => {
      return res;
    }).catch(err => {
      return err;
    });
  }


  async fetchMyAppointments(user: any, selectedUser: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return await this.http.get(this.baseURL + 'booking/Doctor/' + selectedUser + '/' + user.SelectedOrg, {headers}).toPromise().then(async (appointmentList: any) => {
      return appointmentList;
    });
  }


  createUserService(userService, user, doctorId) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    const body = {
      cost: userService.cost,
      timeSlot: userService.timeSlot,
      startTime: userService.startTime,
      endTime: userService.endTime,
      serviceDays: userService.serviceDays,
      service: userService.services,
      organization: this.userData.SelectedOrg,
      status: userService.status,
    }
    return this.http.post(this.baseURL + 'service/userService/create/' + doctorId, body, {headers}).toPromise().then((res) => {
      return res;
    }).catch((err) => {
      return err;
    })
  }

  ChangePassword(body, user) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.patch(this.baseURL + 'users/' + user.auth.data._id, body, {headers}).toPromise().then(
      (res) => {
        return res;
      })
  }


  async fetch_urlbased_org(body: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return await this.http.post(this.baseURL + 'org/urlBasedOnOrgGrp', body, {headers}).toPromise().then((resp: any) => {
      localStorage.setItem('orgGroupId', resp.data._id);
      // this.getSelectedOrgGrpId(resp.data._id);
      return resp;
    }).catch(err => {
      return err;
    })

  }

  bookAppointment(appointmentDetails, user, prepaid) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    const body = {
      name: appointmentDetails.name,
      date: appointmentDetails.date,
      time: appointmentDetails.time,
      number: appointmentDetails.number,
      service: appointmentDetails.service,
      message: appointmentDetails.message,
      status: true,
      user: appointmentDetails.user,
      doctor: appointmentDetails.doctor,
      organization: this.userData.SelectedOrg
    };
    if (prepaid) {
      body.status = false;
    } else {
      body.status = true;
    }
    return this.http.post(this.baseURL + 'booking/create', body, {headers}).toPromise().then((res) => {
      return res;
    });
  }

// websocket Integration to perform auto refresh Booking
  onSlotBooked(): Observable<any> {
    return new Observable((observer: any) => {
      this.booking_socket?.on('message', (data) => {
        observer.next(data);
      });
    });
  }


// websocket Integration to perform auto refresh Queue System

  onQueuePush(): Observable<any> {
    return new Observable((observer: any) => {
      this.queue_socket?.on('message', (data) => {
        observer.next(data);
      });
    });
  }


  emitEvent(eventName: string, data: string) {
    this.booking_socket.emit(eventName, data);
  }

  queue_emit_Event(eventName: string, data: string) {
    this.queue_socket.emit(eventName, data);
  }


  rescheduleAppointment(user, appointmentData, appointmentId) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    const body = appointmentData;
    return this.http.patch(this.baseURL + 'booking/' + appointmentId, body, {headers}).toPromise().then((res) => {
      // console.log(res);
      return res;
    });
  }


  updateBooking_checkin(user, appointment, body) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.patch(this.baseURL + 'booking/' + appointment._id, body, {headers}).toPromise().then((res) => {
      // console.log(res);
      return res;
    });
  }

  editDoctor(user, userId, data) {
    this.userData = user;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.patch(this.baseURL + 'users/' + userId, data, {headers}).toPromise().then((res) => {
      return res;
    })
  }


  editStaff(user, staffId, data) {
    this.userData = user;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.patch(this.baseURL + 'users/' + staffId, data, {headers}).toPromise().then((res) => {
      return res;
    })
  }

  editPatient(body: any, user: any, patientId) {
    this.userData = user;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    // TODO enable the API call only after the body and structure is fixed to avoid data corruption
    return this.http.patch(this.baseURL + 'users/' + patientId, body, {headers}).toPromise().then((res) => {
      return res;
    }).catch(err => {
      return err;
    })
  }

  editService(body: any, user: any, serviceId) {
    this.userData = user;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.patch(this.baseURL + 'service/' + serviceId, body, {headers}).toPromise().then((res) => {
      return res;
    })
  }

  signinUser(email: string, password: string) {
    return this.http.post(this.baseURL + 'login/', {
      email: email,
      password: password
    }).toPromise().then(async (res: any) => {
      this.userData.auth = {
        token: res.token,
        refreshToken: res.refreshToken,
        userId: res.userId,
        data: res.user
      }
      await this.storage.saveData('pulse-right', JSON.stringify(this.userData));
      await this.SelectedOrgGrpId(JSON.parse(localStorage.getItem('organizationGroup')).data?._id);
      await this.getSelectedOrgId(res.user.organization.length > 0 ?
        res.user.organization[0]._id : JSON.parse(localStorage.getItem('organizationGroup')).data.organization[0]._id);
      return res;
    }).catch(err => {
      return err;
    });
  }


  async getSelectedOrgId(orgId) {
    this.userData.SelectedOrg = orgId;
    await this.storage.saveData('pulse-right', JSON.stringify(this.userData));
  }

  async SelectedOrgGrpId(grpId) {
    this.userData.SelectedOrgGrp = grpId;
    await this.storage.saveData('pulse-right', JSON.stringify(this.userData));
  }


  refreshAuthToken() {
    const body = {
      refreshToken: this.userData.auth.refreshToken
    }
    return this.http.post(this.baseURL + 'login/renewAccessToken', body).toPromise().then(async (res: any) => {
      this.userData.auth.token = res.accessToken;
      await this.storage.saveData('pulse-right', JSON.stringify(this.userData));
    });
  }

  loginPhoneUser(number: string) {
    return this.http.post(this.baseURL + 'login/forgotPassword', {number: number}).toPromise().then((resp: any) => {
      return resp;
    });
  }

  login_phone(phonenumer: string) {
    return this.http.post(this.baseURL + 'login/loginPhone', {number: phonenumer}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    });
  }

  verifyOtp(number: string, otp: string) {
    return this.http.post(this.baseURL + 'login/verifyOtp', {
      number: number,
      otp: otp
    }).toPromise().then(async (res: any) => {
      this.userData.auth = {
        token: res.token,
        refreshToken: res.refreshToken,
        userId: res.user._id,
        data: res.user
      }
      this.userData.SelectedOrg = localStorage.getItem('organization');
      localStorage.setItem('User', JSON.stringify(this.userData));
      return this.userData;
    }).catch(err => {
      return err;
    });
  }


  fetch_all_organization(user: any) {

  }

  changePassword(body) {
    return this.http.post(this.baseURL + 'login/changePassword/verifyOtp', body).toPromise().then(async (res: any) => {
      await this.storage.saveData('pulse-right', JSON.stringify(res));
    });
  }

  async fetchAllTimeoff(user) {
    this.userData = user;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return await this.http.get(this.baseURL + 'timeoff/' + this.userData.SelectedOrg, {headers}).toPromise().then(async (timeoffList) => {
      this.userData.timeoffList = timeoffList;
      await this.storage.saveData('pulse-right', JSON.stringify(this.userData));
    });
  }

  async fetchDoctorTimeoff(user, doctor) {
    this.userData = user;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return await this.http.get(this.baseURL + 'timeoff/getSpecificTimeOffs/' + doctor._id, {headers}).toPromise().then(async (timeoffList) => {
      this.userData.timeOffList = timeoffList;
      await this.storage.saveData('pulse-right', JSON.stringify(this.userData));
      return timeoffList;
    });
  }

  async fetchDoctorCustomTimeoff(user, doctor) {
    this.userData = user;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return await this.http.get(this.baseURL + 'customTime/getSpecificCustomTimeOffs/' + doctor._id, {headers}).toPromise().then(async (timeoffList) => {
      this.userData.overTime = timeoffList;
      await this.storage.saveData('pulse-right', JSON.stringify(this.userData));
      return timeoffList;
    });
  }

  async fetchAllCustTimeoff(user) {
    this.userData = user;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return await this.http.get(this.baseURL + 'customTime/' + this.userData.SelectedOrg, {headers}).toPromise().then(async (timeoffList) => {
      this.userData.custTimeOffList = timeoffList;
      await this.storage.saveData('pulse-right', JSON.stringify(this.userData));
    });
  }


  async createTimeoff(timeOffData) {
    const body = timeOffData;
    body.organization = this.userData.SelectedOrg;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.userData.auth.token + ''
    });
    return await this.http.post(this.baseURL + 'timeoff/create', body, {headers}).toPromise().then(async () => {
    });
  }


  async createCustTimeoff(timeOffData) {
    const body = timeOffData;
    body.organization = this.userData.SelectedOrg;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.userData.auth.token + ''
    });
    return await this.http.post(this.baseURL + 'customTime/create', body, {headers}).toPromise().then(async () => {
    });
  }


  async deleteTimeoff(timeOffId) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.userData.auth.token + ''
    });
    return this.http.delete(this.baseURL + 'timeoff/deletetimeOff/' + timeOffId, {headers}).toPromise().then(async (res) => {
    });
  }


  async deleteCustTimeoff(timeOffId) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.userData.auth.token + ''
    });
    return this.http.delete(this.baseURL + 'customTime/deleteCustomTimeOff/' + timeOffId, {headers}).toPromise().then(async (res) => {
    });
  }

  async fetchAllDoctors(user, org) {
    this.userData = user;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return await this.http.get(this.baseURL + 'users/org/Doctor/' + org, {headers}).toPromise().then(async (doctorList: any) => {
      this.userData.doctorList = doctorList.filter((doctor) => {
        return doctor.status === true;
      });
      await this.storage.saveData('pulse-right', JSON.stringify(this.userData));
      return doctorList;
    });
  }


  async fetchAllAppointmentsByDate(user, start: string, end: string) {
    this.userData = user;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return await this.http.get(this.baseURL + 'booking/org/' + moment(start).format().split('+')[0] + '/' + moment(end).format().split('+')[0] + '/' + this.userData.SelectedOrg, {headers}).toPromise().then(async (appointmentList) => {
      return appointmentList;
    });
  }


  async fetchAllAppointmentsByOrg(user) {
    this.userData = user;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return await this.http.get(this.baseURL + 'booking/org/' + this.userData.SelectedOrg, {headers}).toPromise().then(async (appointmentList) => {
      this.userData.appointmentList = appointmentList;
      await this.storage.saveData('pulse-right', JSON.stringify(this.userData));
      return appointmentList;
    });
  }


// api to dashboard statistics for particular organization

  async dashboard_stats(org: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return await this.http.get(this.baseURL + 'users/dashboard/stats/' + org, {headers}).toPromise().then(async (resp: any) => {
      this.userData.dashboard = resp;
      await this.storage.saveData('pulse-right', JSON.stringify(this.userData));
      return resp;
    });
  }


  async fetchAllPatients(user, pageNum) {
    this.userData = user;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return await this.http.get(this.baseURL + 'users/org/Patient/' + this.userData.SelectedOrg + '?page=' + pageNum + '&limit=10', {headers}).toPromise()
      .then(async (patientList) => {
        this.userData.patientList = patientList;
        await this.storage.saveData('pulse-right', JSON.stringify(this.userData));
        return patientList;
      });
  }


  async fetchAllStaff(user) {
    this.userData = user;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return await this.http.get(this.baseURL + 'users/org/Staff/' + this.userData.SelectedOrg, {headers}).toPromise().then(async (staffList) => {
      this.userData.StaffList = staffList;
      await this.storage.saveData('pulse-right', JSON.stringify(this.userData));
    });
  }


  async fetchPatientByPhone(user, phone) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return await this.http.get(this.baseURL + 'users/phone/' + phone, {headers}).toPromise().then(resp => {
      return resp;
    }).catch(err => {
      return err;
    });
  }


  async searchCustomerBy(user, data, searchBy) {
    const platform = JSON.parse(localStorage.getItem('platform'));
    const headers = {
      'Content-Type': 'application/json',
      'client_id': 'CPDPlatform-1.0.0',
      'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImpvaG5ncmVlbmhvdXNlQGdtYWlsLmNvbSIsInVzZXJJZCI6IjY1MTY4MDI4MTM5ZmMxYmExNmM0NzZjNiIsImNsaWVudF9pZCI6IkNQRFBsYXRmb3JtLTEuMC4wIiwiaWF0IjoxNzA0NzE1MjQwLCJleHAiOjE3MDczMDcyNDB9.x3gdfaE39J-8pLL7bw818k0caN07yadoEBiRT824OLM'
    };
    return this.http.get(this.cloneUrl + 'users/searchUser?platformId=' + platform._id + '&' + searchBy + '=' + data, {headers: headers}).toPromise().then(async (res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }

  async uploadScript_image(user: any, file: any) {
    const platform = JSON.parse(localStorage.getItem('platform'));
    const headers = {
      'client_id': 'CPDPlatform-1.0.0',
      'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImpvaG5ncmVlbmhvdXNlQGdtYWlsLmNvbSIsInVzZXJJZCI6IjY1MTY4MDI4MTM5ZmMxYmExNmM0NzZjNiIsImNsaWVudF9pZCI6IkNQRFBsYXRmb3JtLTEuMC4wIiwiaWF0IjoxNzA0NzE1MjQwLCJleHAiOjE3MDczMDcyNDB9.x3gdfaE39J-8pLL7bw818k0caN07yadoEBiRT824OLM'
    };
    const formData = new FormData();
    formData.append('uploadedScripts', file, file.name);

    return this.http.post(this.cloneUrl + 'orders/upload', formData, {headers: headers}).toPromise().then(async (res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }

  createService(service, user) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    const body = {
      name: service.name,
      organization: this.userData.SelectedOrg,
      message: service.message
    }
    return this.http.post(this.baseURL + 'service/create', body, {headers}).toPromise().then((res) => {
      return res;
    });
  }


  deletePatient(patientId, user) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.delete(this.baseURL + 'users/' + patientId, {headers}).toPromise().then((res) => {
      return res;
    });
  }

  nextPage(user, page1) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.get(this.baseURL + 'users/getPatients/getAll?pageNumber=' + page1 + '&perPageLimit=10', {headers}).toPromise().then((res) => {
      return res;
    });
  }

  serachPatient(user, name) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.get(this.baseURL + 'users/getPatientByName/' + name, {headers}).toPromise().then((res) => {
      return res;
    }).catch(err => {
      return err;
    });
  }


  deleteService(serviceId, user) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.delete(this.baseURL + 'service/' + serviceId, {headers}).toPromise().then((res) => {
      return res;
    });
  }


  deleteDoctor(doctorId, user) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.delete(this.baseURL + 'users/' + doctorId, {headers}).toPromise().then((res) => {
      return res;
    });
  }


  AddDependents(dependent, userData, parentId) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.auth.token + ''
    });
    const body = {
      name: dependent.name,
      userType: 'Patient',
      role: 'User',
      organization: this.userData.SelectedOrg,
      dateOfBirth: moment(dependent.dob).format() === 'Invalid date' ? null : moment(dependent.dob).format(),
      gender: dependent.gender,
      addData: dependent.addData,
    }

    return this.http.post(this.baseURL + 'users/dependent/register/' + parentId, body, {headers}).toPromise().then((res: any) => {
      return res;
    }).catch(err => {
      return err;
    })

  }

  async fetchAllServices(user, org: any) {
    this.userData = user;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });

    return await this.http.get(this.baseURL + 'service/' + org, {headers}).toPromise().then(async (serviceList) => {
      this.userData.serviceList = serviceList;
      await this.storage.saveData('pulse-right', JSON.stringify(this.userData));
      return serviceList;
    });
  }

  async fetchAllBookings(user, orgId) {
    this.userData = user;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return await this.http.get(this.baseURL + 'booking/org/' + orgId, {headers}).toPromise().then(async (appointmentList) => {
      this.userData.appointmentList = appointmentList;
      await this.storage.saveData('pulse-right', JSON.stringify(this.userData));
    });
  }

  async fetchUserData(user) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return await this.http.get(this.baseURL + 'users/' + user.auth.data._id, {headers}).toPromise().then(resp => {
      return resp;
    });
  }

  cancelAppointment(appointmentId, user) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.delete(this.baseURL + 'booking/' + appointmentId, {headers}).toPromise().then((res) => {
      return res;
    });
  }


  confirmBooking(body, user, appointmentId: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    // console.log(appointmentId);
    return this.http.patch(this.baseURL + 'booking/' + appointmentId, body, {headers}).toPromise().then((res) => {
      return res;
    })
  }

  logout() {
    localStorage.removeItem('pulse-right');
    localStorage.removeItem('organization');
    history.pushState(null, '', location.href);
    window.onpopstate = () => {
      history.go(1);
    }
    // const logoutStatus = new Promise<void>((resolve, reject) => {
    //   this.storage.clearData();
    //   resolve();
    // });
    // return logoutStatus;
  }

  editUserService(userService, body, user) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.patch(this.baseURL + 'service/userService/update/' + userService._id, body, {headers}).toPromise().then((res) => {
      return res;
    })
  }


  async isAuthenticated(roles) {
    if (JSON.stringify(this.userData.auth) !== '{}') {
      return true;
    } else {
      return false;
    }
  }

  create_Queue(user: any, body: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.post(this.baseURL + 'queue/create', body, {headers}).toPromise().then((res) => {
      return res;
    }).catch(err => {
      return err;
    });
  }

  fetch_queue_for_Doctor(user: any, doctor: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.get(this.baseURL + 'queue/listDoctorQueue/' + user.SelectedOrg + '/' + doctor._id, {headers}).toPromise().then((res) => {
      return res;
    }).catch(err => {
      return err;
    });
  }

  reOrder_que(user, body) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.patch(this.baseURL + 'queue/updatePositions', body, {headers}).toPromise().then((res) => {
      return res;
    }).catch(err => {
      return err;
    });
  }


  // api call to create module

  create_module(user: any, body: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.post(this.baseURL + 'modules/create', body, {headers}).toPromise().then((res) => {
      return res;
    }).catch(err => {
      return err;
    });
  }

  // fetch all modules
  fetch_modules(user: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.get(this.baseURL + 'modules/listAll/modules', {headers}).toPromise().then((res) => {
      return res;
    }).catch(err => {
      return err;
    });
  }


  // api to enable the modules for organization
  enable_modules_for_Org(user: any, orgId: any, body: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.post(this.baseURL + 'org/enableModule/' + orgId, body, {headers}).toPromise().then((res) => {
      return res;
    }).catch(err => {
      return err;
    });
  }


  disable_modules_for_Org(user: any, orgId: any, body: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.post(this.baseURL + 'org/disableTheModule/' + orgId, body, {headers}).toPromise().then((res) => {
      return res;
    }).catch(err => {
      return err;
    });
  }


  // api to delete the module

  deleteModule(user: any, module: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.delete(this.baseURL + 'modules/' + module._id, {headers}).toPromise().then((res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }

  // api to update the module

  update_module(user: any, module: any, body: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.patch(this.baseURL + 'modules/' + module._id, body, {headers}).toPromise().then((res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }


  // get all Organization groups

  fetchAll_OrgGroups(user: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.get(this.baseURL + 'org/groups/organizationGroups', {headers}).toPromise().then((res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }

  // create organization Group

  createOrganization_group(user: any, body: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.post(this.baseURL + 'org/create/organizationGroup', body, {headers}).toPromise().then((res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }


  createOrganization(user: any, body: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.post(this.baseURL + 'org/register', body, {headers}).toPromise().then((res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }

  uploadLogos(user: any, type: string, file: any, selectedOrg) {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    const formData = new FormData();
    formData.append(type, file, file.name)
    return this.http.post(this.baseURL + 'org/upload/' + type + '/' + selectedOrg._id, formData, {headers}).toPromise().then((res: any) => {
        return res;
      }
    ).catch(err => {
    });
  }

  // add organization to OrganizationGroup
  add_Organization_toGrp(user: any, body: any, orgGrpId: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.post(this.baseURL + 'org/addOrganizations/' + orgGrpId._id, body, {headers}).toPromise().then((res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }

  // delete Organization from the Group

  delete_Organization(user: any, orgGrp: any, org: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.delete(this.baseURL + 'org/removeOrganization/'
      + orgGrp._id + '/' + org._id, {headers}).toPromise().then((res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }

  // delete Organization group

  delete_OrganizationGroup(user: any, orgGrp: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.delete(this.baseURL + 'org/deleteOrgGroup/'
      + orgGrp._id, {headers}).toPromise().then((res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }

  update_org_details(user: any, body: any, org: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.patch(this.baseURL + 'org/'
      + org._id, body, {headers}).toPromise().then((res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }


  fetch_organization_Id(user: any, org: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.get(this.baseURL + 'org/' + org, {headers}).toPromise().then((res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }

  // updateOrgGrp

  update_orgGrp_details(user: any, body: any, orgGrp: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.patch(this.baseURL + 'org/updateOrgGrp/'
      + orgGrp._id, body, {headers}).toPromise().then((res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }


  fetch_billing_Org(user: any, org: string, year: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.get(this.baseURL + 'billing/org/' + org + '?year=' + year, {headers}).toPromise().then((res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }

  fetch_billingOrg_month_year(user: any, org: string, year: string, month: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.get(this.baseURL + 'billing/organisation/' + org + '/month/' + year + '/' + month, {headers}).toPromise().then((res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }

  fetch_allBills(user: any, year: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.get(this.baseURL + 'billing/all?year=' + year, {headers}).toPromise().then((res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }


  update_billings(user: any, billingId: any, body: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.patch(this.baseURL + 'billing/' + billingId, body, {headers}).toPromise().then((res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }


  fetchAdmins(user: any, orgId: any, role: string, userType: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.get(this.baseURL + 'users/listUsers/roles?orgId=' + orgId +
      '&role=' + role +
      '&userType=' + userType, {headers}
    ).toPromise().then((res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }

  addProfilePic(user: any, file: any, doctor: any) {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + user.auth.token + ''
    });

    const formData = new FormData();
    formData.append('avatar', file, file.name);
    return this.http.post(this.baseURL + 'users/upload/' + doctor._id, formData, {headers}).toPromise().then((res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }

  removeFromQueue(queue: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post(this.baseURL + 'queue/complete-queue/' + queue._id, {}, {headers}).toPromise().then((res: any) => {
      return res;
    }).catch(err => {
      return err;
    });

  }

  addPromoCode(user: any, body: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.post(this.baseURL + 'modules/createOrUpdatePromoCode', body, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }


  createDue_date(user: any, body: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.post(this.baseURL + 'modules/globalRules', body, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }

  list_due_Dates(user: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.get(this.baseURL + 'modules/listGlobalrules', {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }

  update_due_date(user: any, body: any, globalruleId: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.patch(this.baseURL + 'modules/edit/' + globalruleId._id, body, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }

  deleteGlobalRule(user: any, globalruleId: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.delete(this.baseURL + 'modules/delete/' + globalruleId._id, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }

  fetchSpecificOrgGrp(user: any, orgGrpID: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });

    return this.http.get(this.baseURL + 'org/getSpecificOrganizationGroups/' + orgGrpID, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }

  fetchPharmacy(user: any) {
    const platform = JSON.parse(localStorage.getItem('platform'));
    const headers = {
      'Content-Type': 'application/json',
      'client_id': 'CPDPlatform-1.0.0',
      'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImpvaG5ncmVlbmhvdXNlQGdtYWlsLmNvbSIsInVzZXJJZCI6IjY1MTY4MDI4MTM5ZmMxYmExNmM0NzZjNiIsImNsaWVudF9pZCI6IkNQRFBsYXRmb3JtLTEuMC4wIiwiaWF0IjoxNzA0NzE1MjQwLCJleHAiOjE3MDczMDcyNDB9.x3gdfaE39J-8pLL7bw818k0caN07yadoEBiRT824OLM'
    };
    return this.http.get(this.cloneUrl + 'pharmacy/listPharmacy/platform/' + platform._id, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }


  fetchPharmacyProds(user: any, pharmacy: any) {
    const platform = JSON.parse(localStorage.getItem('platform'));
    const headers = {
      'Content-Type': 'application/json',
      'client_id': 'CPDPlatform-1.0.0',
      'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImpvaG5ncmVlbmhvdXNlQGdtYWlsLmNvbSIsInVzZXJJZCI6IjY1MTY4MDI4MTM5ZmMxYmExNmM0NzZjNiIsImNsaWVudF9pZCI6IkNQRFBsYXRmb3JtLTEuMC4wIiwiaWF0IjoxNzA0NzE1MjQwLCJleHAiOjE3MDczMDcyNDB9.x3gdfaE39J-8pLL7bw818k0caN07yadoEBiRT824OLM'
    };
    return this.http.get(this.cloneUrl + 'pharmacyProduct/listSpecific/' + platform._id + '/' + pharmacy._id + '?page=1&limit=1000',
      {headers: headers}).toPromise().then(async (res: any) => {
      return res;
    });
  }


  createOrder(user: any, body: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.post(this.baseURL + 'orders/trigger/myScript/createOrder/' + user.auth.data._id + '/' + user.SelectedOrg, body, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }


  register_new_user(body: any) {
    return this.http.post(this.cloneUrl + 'users/register', body).toPromise().then(async (res: any) => {
      return res;
    }).catch(err => {
      return err;
    })
  }


  create_Address(body: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      // 'Authorization': 'Bearer ' + user.auth.token + '',
      // 'client_id': user.auth.client_id

    });
    return this.http.post(this.cloneUrl + 'users/addAddress', body, {headers: headers}).toPromise().then(async (res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }

  getAllPharmacy() {
    return this.http.get(this.baseURL + 'pharmacy/listAll/noAuthCheck').toPromise().then(async (res: any) => {
      return res;
    })
  }

  fetchCustomers(user: any, platform) {
    const headers = {
      'Content-Type': 'application/json',
      'client_id': 'CPDPlatform-1.0.0',
      'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImpvaG5ncmVlbmhvdXNlQGdtYWlsLmNvbSIsInVzZXJJZCI6IjY1MTY4MDI4MTM5ZmMxYmExNmM0NzZjNiIsImNsaWVudF9pZCI6IkNQRFBsYXRmb3JtLTEuMC4wIiwiaWF0IjoxNzA0OTU5MjkzLCJleHAiOjE3MDc1NTEyOTN9.u9wffvkEpDUECKZP_L_HA2vzTaABJeUvxGNg029npxs'
    };
    return this.http.get(this.cloneUrl + 'users/getList/allUsers', {headers: headers}).toPromise().then(async (res: any) => {
      return res;
    });
  }


}
